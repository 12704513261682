import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import * as platformClient from 'purecloud-platform-client-v2';
import { campania } from '../../models/hipotecario/campania';
import { agente } from 'src/app/models/hipotecario/agente';
import { auditor } from 'src/app/models/hipotecario/auditor';
import { resultado } from 'src/app/models/hipotecario/resultado';
import superagent from 'superagent';

import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension, EvaluationDetailQueryPredicateDimension, UserDetailQueryPredicateDimension, presenceDetailQueryPredicateDimension } from 'src/app/constants/purecloud';

class getAuthorizationDivisionsOptions implements platformClient.AuthorizationApi.getAuthorizationDivisionsOptions {
  constructor(public pageSize: number) {
  }
}

class getRoutingWrapupcodesOptions implements platformClient.RoutingApi.getRoutingWrapupcodesOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}

class getRoutingQueuesOptions implements platformClient.RoutingApi.getRoutingQueuesOptions {
  constructor(public name?: string, public paging?: PagingSpec) {
  }
}



class AsyncConversationQuery implements platformClient.Models.AsyncConversationQuery {
  constructor(public interval: string, public startOfDayIntervalMatching: boolean, public conversationFilters: Array<ConversationDetailQueryFilter>, public segmentFilters: Array<SegmentDetailQueryFilter>) {
  }
}
class ConversationDetailQueryFilter implements platformClient.Models.ConversationDetailQueryFilter {
  constructor(public type: string, public predicates: Array<ConversationDetailQueryPredicate>) {
  }
}
class ConversationDetailQueryPredicate implements platformClient.Models.ConversationDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}
class SegmentDetailQueryFilter implements platformClient.Models.SegmentDetailQueryFilter {
  constructor(public type: string, public clauses?: Array<SegmentDetailQueryClause>, public predicates?: Array<SegmentDetailQueryPredicate>) {
  }
}
class SegmentDetailQueryFilterWithCause implements platformClient.Models.SegmentDetailQueryFilter {
  constructor(public type: string, public clauses?: Array<SegmentDetailQueryClause>) {
  }
}
class SegmentDetailQueryFilterWithPredicate implements platformClient.Models.SegmentDetailQueryFilter {
  constructor(public type: string, public predicates?: Array<SegmentDetailQueryPredicate>) {
  }
}
class SegmentDetailQueryClause implements platformClient.Models.SegmentDetailQueryPredicate {
  constructor(public type: string, public predicates: Array<SegmentDetailQueryPredicate>) {
  }
}
class SegmentDetailQueryPredicate implements platformClient.Models.SegmentDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}
class DetailsJobResultsOptions implements platformClient.ConversationsApi.getAnalyticsConversationsDetailsJobResultsOptions {
  constructor(public pageSize: number, public cursor?: string) {
  }
}
class ConversationQuery implements platformClient.Models.ConversationQuery {
  constructor(public interval: string, public order?: string, public conversationFilters?: Array<platformClient.Models.ConversationDetailQueryFilter>, public segmentFilters?: Array<platformClient.Models.SegmentDetailQueryFilter>, public evaluationFilters?: Array<platformClient.Models.EvaluationDetailQueryFilter>, public paging?: PagingSpec) {
  }
}
/* class ConversationQuery implements platformClient.Models.ConversationQuery {
  constructor(public interval: string, public conversationFilters: Array<ConversationDetailQueryFilter>, public segmentFilters: Array<SegmentDetailQueryFilter>, public paging: PagingSpec) {
  }
} */
class PagingSpec implements platformClient.Models.PagingSpec {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}
class EvaluationDetailQueryFilter implements platformClient.Models.EvaluationDetailQueryFilter {
  constructor(public type: string, public clauses?: Array<EvaluationDetailQueryClause>, public predicates?: Array<EvaluationDetailQueryPredicate>) {
  }
}

class EvaluationDetailQueryClause implements platformClient.Models.EvaluationDetailQueryClause {
  constructor(public type: string, public predicates: Array<EvaluationDetailQueryPredicate>) {
  }
}

class EvaluationDetailQueryPredicate implements platformClient.Models.EvaluationDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value?: string) {
  }
}

class getEvaluationsQuery implements platformClient.QualityApi.getQualityEvaluationsQueryOptions {
  constructor(public conversationId: string) {
  }
}

class getQualityConversationEvaluationOptions implements platformClient.QualityApi.getQualityConversationEvaluationOptions {
  constructor(public expand: string) {
  }
}

class getFlowsDatatableRowsOptions implements platformClient.ArchitectApi.getFlowsDatatableRowsOptions {
  constructor(public pageNumber: number, public pageSize: number, public showbrief: boolean) {
  }
}

/*    class UserDetailsQuery implements platformClient.Models.UserDetailsQuery{
      constructor(public interval: string,public userFilters?: Array<platformClient.Models.UserDetailQueryFilter>,public paging?: PagingSpec) {
      }
   } */
class UserDetailQueryFilter implements platformClient.Models.UserDetailQueryFilter {
  constructor(public type: string, public predicates: Array<platformClient.Models.UserDetailQueryPredicate>) {
  }
}

class UserDetailQueryPredicate implements platformClient.Models.UserDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value?: string) {
  }
}
/* class getUsersOptions implements platformClient.UsersApi.getUsersOptions{
    constructor(public pageSize?: number, public pageNumber?: number,public userId?: Array<string>) {
    }
 } */
class getUsersOptions implements platformClient.UsersApi.getUsersOptions {
  constructor(public pageSize?: number, public pageNumber?: number, public id?: Array<string>, public expand?: Array<string>) {
  }
}
class getQualityEvaluatorsActivityOptions implements platformClient.QualityApi.getQualityEvaluatorsActivityOptions {
  constructor(public pageSize?: number, public pageNumber?: number) {
  }
}

class UserDetailsQuery implements platformClient.Models.UserDetailsQuery {
  constructor(public interval: string, public userFilters?: Array<platformClient.Models.UserDetailQueryFilter>, public presenceFilters?: Array<platformClient.Models.PresenceDetailQueryFilter>, public paging?: PagingSpec) {
  }
}

class UserAggregationQuery implements platformClient.Models.UserAggregationQuery {
  constructor(public interval: string, public granularity: string, public groupBy: Array<string>, public filter: platformClient.Models.UserAggregateQueryFilter, public metrics: Array<string>) {
  }
}

class UserAggregateQueryFilter implements platformClient.Models.UserAggregateQueryFilter {
  constructor(public type: string, public predicates: Array<platformClient.Models.UserAggregateQueryPredicate>) {
  }
}
class UserAggregateQueryPredicate implements platformClient.Models.UserAggregateQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}

class ConversationAggregationQuery implements platformClient.Models.ConversationAggregationQuery {
  constructor(public interval: string, public granularity: string, public groupBy: Array<string>, public filter: platformClient.Models.ConversationAggregateQueryFilter, public metrics: Array<string>) {
  }
}

class ConversationAggregateQueryFilter implements platformClient.Models.ConversationAggregateQueryFilter {
  constructor(public type: string, public predicates: Array<platformClient.Models.ConversationAggregateQueryPredicate>) {
  }
}

class ConversationAggregateQueryPredicate implements platformClient.Models.ConversationAggregateQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}

class getUsersMeOptionsWithoutIntegration implements platformClient.UsersApi.getUsersMeOptions {
  constructor(public expand: Array<string>){
  }
}



@Injectable({
  providedIn: 'root'
})
export class PurecloudService {
  private apiOutbound: platformClient.OutboundApi;
  private apiAuthorization: platformClient.AuthorizationApi;
  private apiConversations: platformClient.ConversationsApi;
  private apiRouting: platformClient.RoutingApi;
  //private client: platformClient.ApiClientClass;
  private client: any;
  private authData: platformClient.AuthData;
  private apiUsers: platformClient.UsersApi;
  private apiObjects: platformClient.ObjectsApi;
  private apiQuality: platformClient.QualityApi;
  private apiArquitect: platformClient.ArchitectApi;
  private apiGroups: platformClient.GroupsApi;
  private apiAnalitics: platformClient.AnalyticsApi;
  private apiPresences: platformClient.PresenceApi;

  private retryCounter: number;

  constructor() {
    this.apiOutbound = new platformClient.OutboundApi();
    this.apiAuthorization = new platformClient.AuthorizationApi;
    this.apiConversations = new platformClient.ConversationsApi();
    this.apiRouting = new platformClient.RoutingApi();
    this.apiUsers = new platformClient.UsersApi();
    this.apiObjects = new platformClient.ObjectsApi();
    this.apiQuality = new platformClient.QualityApi();
    this.apiArquitect = new platformClient.ArchitectApi();
    this.apiGroups = new platformClient.GroupsApi();
    this.apiAnalitics = new platformClient.AnalyticsApi();
    this.apiPresences = new platformClient.PresenceApi();

    this.retryCounter = 0;
  }

  public getConversations(): platformClient.ConversationsApi {
    return this.apiConversations;
  }
  public getOutbound(): platformClient.OutboundApi {
    return this.apiOutbound;
  }
  public getUsersApi(): platformClient.UsersApi {
    return this.apiUsers;
  }
  public getGroupsApi(): platformClient.GroupsApi {
    return this.apiGroups;
  }

  login(): Promise<any> {
    return new Promise<Array<any>>((resolve, reject) => {
      this.client = platformClient.ApiClient.instance;
      this.client.setEnvironment('https://api.mypurecloud.com');
      this.client.timeout = 50000;
      this.client.callApi = this.callApi;
      this.client.loginImplicitGrant('bab8154e-5c3d-4f1f-865a-27da381eb940', window.location.origin + "/download")
        .then((response) => this.thenLogin(response, resolve))
        .catch((response) => this.catchLogin(response, reject));
    });
  }
  private callApi(path: string, httpMethod: string, pathParams: { [key: string]: string; }, queryParams: { [key: string]: object; }, headerParams: { [key: string]: object; }, formParams: { [key: string]: object; }, bodyParam: any, authNames: Array<string>, contentTypes: Array<string>, accepts: Array<string>): Promise<any>{
    var Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1000
    });
    return new Promise((resolve, reject) => {
			sendRequest(this);
      function sendRequest(that){
        sendRequestRetry(that, 5);
      }
			function sendRequestRetry(that, retryCounter) {
				var url = that.buildUrl(path, pathParams);
				var request = superagent(httpMethod, url);

				if (that.proxy && request.proxy) {
					request.proxy(that.proxy);
				}

				if(that.debugLog){
					var trace = `[REQUEST] ${httpMethod} ${url}`;
					if(pathParams && Object.keys(pathParams).length > 0 && pathParams[Object.keys(pathParams)[0]]){
						trace += `\nPath Params: ${JSON.stringify(pathParams)}`;
					}

					if(queryParams && Object.keys(queryParams).length > 0 && queryParams[Object.keys(queryParams)[0]]){
						trace += `\nQuery Params: ${JSON.stringify(queryParams)}`;
					}

					if(bodyParam){
						trace += `\nnBody: ${JSON.stringify(bodyParam)}`;
					}

					that._debugTrace(trace);
				}

				// apply authentications
				that.applyAuthToRequest(request, authNames);

				// set query parameters
				request.query(that.normalizeParams(queryParams));

				// set header parameters
				request.set(that.defaultHeaders).set(that.normalizeParams(headerParams));
				//request.set({ 'purecloud-sdk': '112.0.0' });

				// set request timeout
				request.timeout(that.timeout);

				var contentType = that.jsonPreferredMime(contentTypes);
				if (contentType) {
					request.type(contentType);
				} else if (!request.header['Content-Type']) {
					request.type('application/json');
				}

				if (contentType === 'application/x-www-form-urlencoded') {
					request.send(that.normalizeParams(formParams));
				} else if (contentType == 'multipart/form-data') {
					var _formParams = that.normalizeParams(formParams);
					for (var key in _formParams) {
						if (_formParams.hasOwnProperty(key)) {
							if (that.isFileParam(_formParams[key])) {
								// file field
								request.attach(key, _formParams[key]);
							} else {
								request.field(key, _formParams[key]);
							}
						}
					}
				} else if (bodyParam) {
					request.send(bodyParam);
				}

				var accept = that.jsonPreferredMime(accepts);
				if (accept) {
					request.accept(accept);
				}
				request.end((error, response) => {
					if (error) {
						if (!response) {
							reject({
								status: 0,
								statusText: 'error',
								headers: [],
								body: {},
								text: 'error',
								error: error
							});
							return;
						}
					}

          //? Retry
          if(( response.statusText === 'timeout' || response.status === 504) && retryCounter > 0){
            retryCounter--;
            Toast.fire({
              title: 'Reintento ' + (5-retryCounter) + ' de 5',
              timer: 1000
            });
            sendRequestRetry(that, retryCounter);
            return;
          }
          if( retryCounter <= 0){
            Toast.fire({
              title: 'Se cancelo por reintentos',
              timer: 1000
            });
          }
					// Build response object
					var data = (that.returnExtended === true || error) ? {
						status: response.status,
						statusText: response.statusText,
						headers: response.headers,
						body: response.body,
						text: response.text,
						error: error
					} : response.body ? response.body : response.text;

					// Debug logging
					if (that.debugLog) {
						var trace = `[RESPONSE] ${response.status}: ${httpMethod} ${url}`;
						if (response.headers)
							trace += `\ninin-correlation-id: ${response.headers['inin-correlation-id']}`;
						if (response.body)
							trace += `\nBody: ${JSON.stringify(response.body,null,2)}`;

						// Log trace message
						that._debugTrace(trace);

						// Log stack trace
						if (error)
							that._debugTrace(error);
					}

					// Resolve promise
					if (error) {
						if (data.status == 401 && that.shouldRefreshAccessToken && that.authData.refreshToken !== "") {
							that._handleExpiredAccessToken()
								.then(() => {
									sendRequest(that);
								})
								.catch((err) => {
									reject(err);
								});
						} else {
							reject(data);
						}
					} else {
						resolve(data);
					}
				});
			}
		});
  }
  private thenLogin(response: platformClient.AuthData, resolve) {
    this.authData = response;
    resolve(response);
  }
  private catchLogin(response: any, reject) {
    console.info(response);
    reject(response);
  }

  public checkLogin() {
    var now = new Date();
    //console.log(now.getTime() + ' ... ' + (this.authData.tokenExpiryTime - 10000));
    if (now.getTime() > this.authData.tokenExpiryTime - 10000) {
      return new Promise((resolve, reject) => {
        reject({ status: '401', code: 'bad.credentials', message: 'Credenciales inválidas.' });
      });
    }
    return undefined;
  }

  private getPredicateConversationEnd(type: string, ids: string[]): Object {
    var predicate = {};
    predicate[PredicateDimension.conversation] = {}
    predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.conversationEnd] = [];
    predicate[PredicateDimension.segment] = {}
    predicate[PredicateDimension.segment][type] = ids;
    return predicate;
  }

  public postAnalyticsConversationsDetailsQueryByUserIds(from: Date, to: Date, userIds: string[], index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var predicate = this.getPredicateConversationEnd(SegmentDetailQueryPredicateDimension.userId, userIds);
    return this.postAnalyticsConversationsDetailsQuery(from, to, undefined, predicate, index);
  }

  public async postAnalyticsConversationsDetailsQueryByQueueNames(from: Date, to: Date, queueNames: string[], index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    let queueIds = await this.getQueueIds(queueNames);
    return this.postAnalyticsConversationsDetailsQueryByQueueIds(from, to, queueIds, index);
  }
  public postAnalyticsConversationsDetailsQueryByQueueIds(from: Date, to: Date, queueIds: string[], index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var predicate = this.getPredicateConversationEnd(SegmentDetailQueryPredicateDimension.queueId, queueIds);
    return this.postAnalyticsConversationsDetailsQuery(from, to, undefined, predicate, index);
  }
  //recibira dos tipos de predicados: predicateClause, predicate
  public postAnalyticsConversationsDetailsQuery(from: Date, to: Date, predicateClause, predicate, index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var promise = this.checkLogin();
    if (promise !== undefined) {
      return promise;
    }

    var aPredicate = undefined;
    if (predicate !== undefined) { aPredicate = predicate[PredicateDimension.segment]; }

    var aPredicateClause = undefined;
    if (predicateClause !== undefined) { aPredicateClause = predicateClause[PredicateDimension.segment]; }
    //console.log(aPredicateClause);

    var conversationDetailQueryFilter = predicate === undefined || predicate[PredicateDimension.conversation] === undefined ? undefined : [this.getConversationDetailQueryFilter(predicate[PredicateDimension.conversation])];

    var evaluationDetailQueryFilter = predicate === undefined || predicate[PredicateDimension.evaluation] === undefined ? undefined : [this.getEvaluationDetailQueryFilter(predicate[PredicateDimension.evaluation])];
    var segmentDetailQueryFilter = (aPredicate === undefined && aPredicateClause === undefined) ? undefined : [this.getSegmentDetailQueryFilter(aPredicateClause, aPredicate)];
    console.log("query filter: ",segmentDetailQueryFilter);
    var query = new ConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), 'asc', conversationDetailQueryFilter, segmentDetailQueryFilter, evaluationDetailQueryFilter, new PagingSpec(100, index));
    console.log("ConversationQuery, interval: ", query.interval, ", pageNumber", query.paging.pageNumber, query);
    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsQuery(query);
  }

  /*public async postAnalyticsConversationsDetailsJobsByQueueNames(from: Date, to: Date, queueNames: string[]): Promise<platformClient.Models.AsyncQueryResponse> {
    let queueIds = await this.getQueueIds(queueNames);
    var predicate = this.getPredicateConversationEnd(SegmentDetailQueryPredicateDimension.queueId, queueIds);

    return this.postAnalyticsConversationsDetailsJobs(from, to, predicate);
  }
  public async postAnalyticsConversationsDetailsJobsByUserIds(from: Date, to: Date, userIds: string[]): Promise<platformClient.Models.AsyncQueryResponse> {
    var predicate = this.getPredicateConversationEnd(SegmentDetailQueryPredicateDimension.userId, userIds);

    return this.postAnalyticsConversationsDetailsJobs(from, to, predicate);
  }
*/
  public postAnalyticsConversationsDetailsJobs(from: Date, to: Date, predicate,predicateClause): Promise<platformClient.Models.AsyncQueryResponse> {
    var promise = this.checkLogin();
    if (promise !== undefined) {
      return promise;
    }
    var aPredicate = undefined;
    if (predicate !== undefined) { aPredicate = predicate[PredicateDimension.segment]; }

    var aPredicateClause = undefined;
    if (predicateClause !== undefined) { aPredicateClause = predicateClause[PredicateDimension.segment]; }



    //TODO Pasarle ambos predicados e imitar lo del que llamagetSegmentDetailQueery Filter del otro
    var conversationDetailQueryFilter = predicate === undefined || predicate[PredicateDimension.conversation] === undefined ? undefined : [this.getConversationDetailQueryFilter(predicate[PredicateDimension.conversation])];
    var segmentDetailQueryFilter =  (aPredicate === undefined && aPredicateClause === undefined) ? undefined : [this.getSegmentDetailQueryFilter(aPredicateClause, aPredicate)];
    var query = new AsyncConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), false, conversationDetailQueryFilter, segmentDetailQueryFilter);
    console.log("AsyncConversationQuery: ", query);
    console.log("AsyncConversationQuery: ", query.conversationFilters);
    console.log("AsyncConversationQuery: ", query.interval);
    console.log("AsyncConversationQuery: ", query.segmentFilters);
    console.log("AsyncConversationQuery: ", query.startOfDayIntervalMatching);
    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsJobs(query);
  }

  public getDivisions(): Promise<any> {
    return this.apiAuthorization.getAuthorizationDivisions(new getAuthorizationDivisionsOptions(100))
      .then((result) => {
        var divisions = {};
        result.entities.forEach((division) => divisions[division.id] = division.name);
        return divisions;
      }).catch((error) => {
        return undefined;
      });
  }
  private getQueueName(queue: string): Promise<string> {
    return this.apiRouting.getRoutingQueues(new getRoutingQueuesOptions(queue))
      .then((result) => {
        if (result.entities.length > 0)
          return result.entities[0].id;
        return undefined;
      }).catch((error) => {
        return undefined;
      });
  }

  getAnalyticsConversationsDetailsJob(jobId: string): Promise<platformClient.Models.AsyncQueryStatus> {
    return this.checkLogin() || this.apiConversations.getAnalyticsConversationsDetailsJob(jobId);
  }

  getAnalyticsConversationsDetailsJobResults(jobId: string, cursor?: string): Promise<platformClient.Models.AnalyticsConversationAsyncQueryResponse> {
    return this.checkLogin() || this.apiConversations.getAnalyticsConversationsDetailsJobResults(jobId, new DetailsJobResultsOptions(750, cursor));
  }

  getWraUps(): Promise<any> {
    return this.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getWraUpsInternal(new getRoutingWrapupcodesOptions(100, 1), {}, resolve, reject);
    });
  }
  private getWraUpsInternal(opts: getRoutingWrapupcodesOptions, wraups, resolve, reject) {
    this.apiRouting.getRoutingWrapupcodes(opts)
      .then((response) => this.thenWraUps(response, wraups, resolve, reject))
      .catch((response) => this.catchWraUps(response, reject));
  }
  private thenWraUps(response: platformClient.Models.WrapupCodeEntityListing, wraups, resolve, reject) {
    response.entities.forEach((wraup) => wraups[wraup.id] = wraup.name);
    if (response.pageNumber < response.pageCount) {
      this.getWraUpsInternal(new getRoutingWrapupcodesOptions(100, (response.pageNumber + 1)), wraups, resolve, reject);
    } else {
      resolve(wraups);
    }
  }
  private catchWraUps(response: any, reject) {
    reject(response);
  }

  public convertDateToString(date: Date): string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();
    var msecond = '' + date.getMilliseconds();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;
    if (msecond.length < 2)
      msecond = '0' + msecond;
    if (msecond.length < 3)
      msecond = '0' + msecond;

    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + msecond + 'Z';
  }

  private getSegmentDetailQueryFilter(predicateClause, predicate): SegmentDetailQueryFilter {


    if (predicate === undefined && predicateClause === undefined)
      return undefined;
    var segmentDetailQueryPredicates: SegmentDetailQueryPredicate[] = [];
    // console.log(predicateClause);
/*********************************************************************** */


var aSegmentDetailQueryClause: SegmentDetailQueryClause[] = [];
    var segmentDetailQueryPredicatesClause: SegmentDetailQueryPredicate[] = [];
    if (predicateClause !== undefined) {

      var dim = SegmentDetailQueryPredicateDimension.DIMENSIONS[0];
      console.log(dim);
      SegmentDetailQueryPredicateDimension.DIMENSIONS.forEach(dimension => {
        var values = predicateClause[dimension];
        if (values !== undefined) {
          segmentDetailQueryPredicatesClause = [];
          if (values.length > 0) {
            //console.log(values.length);
            for (var i = 0; i < values.length; i++) {
              segmentDetailQueryPredicatesClause.push(new SegmentDetailQueryPredicate("dimension", dimension, "matches", values[i]));
            }
            //  aSegmentDetailQueryClause.push(new SegmentDetailQueryClause('or',segmentDetailQueryPredicatesClause));
          } else {
            segmentDetailQueryPredicatesClause.push(new SegmentDetailQueryPredicate("dimension", dimension, "exists", undefined));
            // aSegmentDetailQueryClause.push(new SegmentDetailQueryClause('or',segmentDetailQueryPredicatesClause));
          }


          aSegmentDetailQueryClause.push(new SegmentDetailQueryClause('or', segmentDetailQueryPredicatesClause));
        }

      });
    }

    if (predicate !== undefined) {
      var aSegmentDetailQueryPredicate = [];
      SegmentDetailQueryPredicateDimension.DIMENSIONS.forEach(dimension => {
        var values = predicate[dimension];
        if (values !== undefined) {
          if (values.length > 0) {
            for (var i = 0; i < values.length; i++) {
              segmentDetailQueryPredicates.push(new SegmentDetailQueryPredicate("dimension", dimension, "matches", values[i]));
            }
          } else {
            segmentDetailQueryPredicates.push(new SegmentDetailQueryPredicate("dimension", dimension, "exists", undefined));
          }

        }
      });
      aSegmentDetailQueryClause.push(new SegmentDetailQueryClause('or', segmentDetailQueryPredicates));
    }

/*
    if(segmentDetailQueryPredicates.length > 0 && segmentDetailQueryPredicatesClause.length > 0){
      return new SegmentDetailQueryFilter("and", aSegmentDetailQueryClause, segmentDetailQueryPredicates);
    } else {
      if (segmentDetailQueryPredicates.length > 0){
        return new SegmentDetailQueryFilterWithPredicate("and", segmentDetailQueryPredicates);
      } else {
        return new SegmentDetailQueryFilterWithCause("and", aSegmentDetailQueryClause);
      }
    }*/
    return new SegmentDetailQueryFilterWithCause("and", aSegmentDetailQueryClause);

  }

  private getEvaluationDetailQueryFilter(predicate): EvaluationDetailQueryFilter {
    if (predicate === undefined)
      return undefined;
    var evaluationDetailQueryPredicates: EvaluationDetailQueryPredicate[] = [];
    EvaluationDetailQueryPredicateDimension.DIMENSIONS.forEach(dimension => {
      var values = predicate[dimension];
      if (values !== undefined) {
        if (values.length > 0) {
          for (var i = 0; i < values.length; i++) {
            evaluationDetailQueryPredicates.push(new EvaluationDetailQueryPredicate("dimension", dimension, "matches", values[i]));
          }
        } else {
          evaluationDetailQueryPredicates.push(new EvaluationDetailQueryPredicate("dimension", dimension, "exists", undefined));
        }
      }
    });
    return evaluationDetailQueryPredicates.length > 0 ? new EvaluationDetailQueryFilter("or", undefined, evaluationDetailQueryPredicates) : undefined;
  }

  private getConversationDetailQueryFilter(predicate): ConversationDetailQueryFilter {
    if (predicate === undefined)
      return undefined;
    var conversationDetailQueryPredicates: ConversationDetailQueryPredicate[] = [];
    ConversationDetailQueryPredicateDimension.DIMENSIONS.forEach(dimension => {
      var values = predicate[dimension];
      if (values !== undefined) {
        if (values.length > 0) {
          for (var i = 0; i < values.length; i++) {
            conversationDetailQueryPredicates.push(new ConversationDetailQueryPredicate("dimension", dimension, "matches", values[i]));
          }
        } else {
          conversationDetailQueryPredicates.push(new ConversationDetailQueryPredicate("dimension", dimension, "exists", undefined));
        }
      }
    });
    return conversationDetailQueryPredicates.length > 0 ? new ConversationDetailQueryFilter("or", conversationDetailQueryPredicates) : undefined;
  }

  private getQueueId(queue: string): Promise<string> {
    return this.apiRouting.getRoutingQueues(new getRoutingQueuesOptions(queue))
      .then((result) => {
        if (result.entities.length > 0)
          return result.entities[0].id;
        return undefined;
      }).catch((error) => {
        return undefined;
      });
  }

  public async getQueueIds(queueNames: string[]): Promise<Array<string>> {
    var queueIds = new Array<string>();
    for (var i = 0; i < queueNames.length; i++) {
      let name = await this.getQueueId(queueNames[i]);
      if (name === undefined) {
        return new Promise((resolve, reject) => reject({ status: '999', code: 'bad.queue', message: 'No se encontro la QUEUE: ' + queueNames[i] + '.' }));
      }
      queueIds.push(name);
    }
    return new Promise((resolve, reject) => resolve(queueIds));
  }
  /**combo sectores */
  //getQualityFormsEvaluations(opts?: QualityApi.getQualityFormsEvaluationsOptions): Promise<Models.EvaluationFormEntityListing>;
  getQualityFormsEvaluations(index: number): Promise<platformClient.Models.EvaluationFormEntityListing> {

    return this.checkLogin() || this.apiQuality.getQualityFormsEvaluations(new PagingSpec(100, index));
  }

  /* combo campañas */
  getRoutingQueues(name: string, index?: number): Promise<platformClient.Models.QueueEntityListing> {

    // console.log(name);
    var getRoutingQueuesOptions_ = new getRoutingQueuesOptions(name, new PagingSpec(100, index));
    //console.log(getRoutingQueuesOptions_);
    return this.checkLogin() || this.apiRouting.getRoutingQueues(getRoutingQueuesOptions_);
  }

  getRoutingQueueMembers(queueId: string, index?: number): Promise<platformClient.Models.QueueMemberEntityListing> {
    return this.checkLogin() || this.apiRouting.getRoutingQueueMembers(queueId, new PagingSpec(100, index));
  }

  getQualityEvaluatorsActivity(pageSize: number, index?: number): Promise<platformClient.Models.EvaluatorActivityEntityListing> {

    let opts = new getQualityEvaluatorsActivityOptions(pageSize, index);


    return this.checkLogin() || this.apiQuality.getQualityEvaluatorsActivity(opts);
  }

  //postAnalyticsConversationsDetailsQuery(body: platformClient.Models.ConversationQuery): Promise<Models.AnalyticsConversationQueryResponse>;
  postAnalyticsConversationsDetailsQuery_calidad(from: Date, to: Date, campanias: Array<campania>, agentes: Array<agente>, resultados: Array<resultado>, auditores: Array<auditor>, campanias_dataTable: Array<campania>, index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {

    var aEvaluationDetailQueryPredicate: EvaluationDetailQueryPredicate[] = [];
    if (auditores.length > 0) {
      auditores.forEach((auditor) => {
        if (auditor.value === undefined)
          return new Promise((resolve, reject) => {
            reject({ status: '999', code: 'bad.queue', message: 'No se encontro el auditor: ' + auditor.viewValue + '.' });
          });
        aEvaluationDetailQueryPredicate.push(new EvaluationDetailQueryPredicate("dimension", "evaluatorId", "Matches", auditor.value));
      })
    } else {
      aEvaluationDetailQueryPredicate.push(new EvaluationDetailQueryPredicate("dimension", "evaluationId", "exists", undefined));
    }
    // console.log(aEvaluationDetailQueryPredicate);
    var EvaluationDetailQueryFilter_ = new EvaluationDetailQueryFilter("or", undefined, aEvaluationDetailQueryPredicate);

    var segmentDetailQueryClause: SegmentDetailQueryClause[] = [];
    var segmentDetailQueryPredicates_campania: SegmentDetailQueryPredicate[] = [];
    var segmentDetailQueryPredicates_agentes: SegmentDetailQueryPredicate[] = [];
    var segmentDetailQueryPredicates_resultados: SegmentDetailQueryPredicate[] = [];

    let aCampanias = (campanias.length > 0) ? campanias : campanias_dataTable;
    if (aCampanias.length > 0) {
      aCampanias.forEach((campania) => {
        if (campania.value === undefined)
          return new Promise((resolve, reject) => {
            reject({ status: '999', code: 'bad.queue', message: 'No se encontro l campania: ' + campania.viewValue + '.' });
          });
        segmentDetailQueryPredicates_campania.push(new SegmentDetailQueryPredicate("dimension", "queueId", "Matches", campania.value));
      })
      segmentDetailQueryClause.push(new SegmentDetailQueryClause('or', segmentDetailQueryPredicates_campania));
    }

    if (agentes.length > 0) {
      agentes.forEach((agente) => {
        if (agente.value === undefined)
          return new Promise((resolve, reject) => {
            reject({ status: '999', code: 'bad.queue', message: 'No se encontro el agente: ' + agente.viewValue + '.' });
          });
        segmentDetailQueryPredicates_agentes.push(new SegmentDetailQueryPredicate("dimension", "userId", "Matches", agente.value));
      });
      segmentDetailQueryClause.push(new SegmentDetailQueryClause('or', segmentDetailQueryPredicates_agentes));
    }

    if (resultados.length > 0) {
      resultados.forEach((resultado) => {
        //console.log(queue.viewValue);
        if (resultado.value === undefined)
          return new Promise((resolve, reject) => {
            reject({ status: '999', code: 'bad.queue', message: 'No se encontro la campaña: ' + resultado.viewValue + '.' });
          });

        segmentDetailQueryPredicates_resultados.push(new SegmentDetailQueryPredicate("dimension", "wrapUpCode", "matches", resultado.value));
      });
      segmentDetailQueryClause.push(new SegmentDetailQueryClause('or', segmentDetailQueryPredicates_resultados));
    }


    var segmentDetailQueryFilter_ = new SegmentDetailQueryFilter("and", segmentDetailQueryClause, undefined);
    var filtrados = new ConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), undefined, null, [segmentDetailQueryFilter_], [EvaluationDetailQueryFilter_], new PagingSpec(100, index));
    console.log(filtrados);

    //return this.checkLogin();
    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsQuery(new ConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), undefined, null, [segmentDetailQueryFilter_], [EvaluationDetailQueryFilter_], new PagingSpec(100, index)));

  }



  //getQualityEvaluationsQuery(opts?: QualityApi.getQualityEvaluationsQueryOptions): Promise<platformClient.Models.EvaluationEntityListing>;
  getQualityEvaluationsQuery(conversationId: string): Promise<platformClient.Models.EvaluationEntityListing> {

    var getEvaluationsQuery_ = new getEvaluationsQuery(conversationId);
    //console.log(getEvaluationsQuery_);

    return this.checkLogin() || this.apiQuality.getQualityEvaluationsQuery(getEvaluationsQuery_);
  }

  //getQualityConversationEvaluation(conversationId: string, evaluationId: string, opts?: QualityApi.getQualityConversationEvaluationOptions): Promise<Models.Evaluation>;
  getQualityConversationEvaluation(conversationId: string, evaluationId: string): Promise<platformClient.Models.Evaluation> {

    return this.checkLogin() || this.apiQuality.getQualityConversationEvaluation(conversationId, evaluationId, new getQualityConversationEvaluationOptions('agent,evaluator,evaluationForm'));
  }

  //getFlowsDatatableRows(datatableId: string, opts?: ArchitectApi.getFlowsDatatableRowsOptions): Promise<Models.DataTableRowEntityListing>;
  getFlowsDatatableRows(datatableId: string, showbrief: false, index: number): Promise<platformClient.Models.DataTableRowEntityListing> {

    var getFlowsDatatableRowsOptions_ = new getFlowsDatatableRowsOptions(index, 100, showbrief);
    console.log(getFlowsDatatableRowsOptions_);

    return this.checkLogin() || this.apiArquitect.getFlowsDatatableRows(datatableId, getFlowsDatatableRowsOptions_);

  }

  //getRoutingQueueWrapupcodes(queueId: string, opts?: RoutingApi.getRoutingQueueWrapupcodesOptions): Promise<Models.WrapupCodeEntityListing>;
  getRoutingQueueWrapupcodes(queueId: string, index: number): Promise<platformClient.Models.WrapupCodeEntityListing> {

    return this.checkLogin() || this.apiRouting.getRoutingQueueWrapupcodes(queueId, new PagingSpec(100, index));
  }

  // getRoutingQueue(queueId: string): Promise<Models.Queue>;
  getRoutingQueue(queueId: string): Promise<platformClient.Models.Queue> {
    return this.checkLogin() || this.apiRouting.getRoutingQueue(queueId);
  }
  /**apis utilizadas para reportes customer y ventas - servicios */
  //getGroups(opts?: GroupsApi.getGroupsOptions): Promise<Models.GroupEntityListing>;
  getGroups(index: number): Promise<platformClient.Models.GroupEntityListing> {
    return this.checkLogin() || this.apiGroups.getGroups(new PagingSpec(100, index));
  }

  getMyGroups(): Promise< any | platformClient.Models.UserMe> {
    return this.checkLogin() || this.apiUsers.getUsersMe(new getUsersMeOptionsWithoutIntegration(new Array('groups')));
  }

  //getGroupMembers(groupId: string, opts?: GroupsApi.getGroupMembersOptions): Promise<Models.UserEntityListing>;
  getGroupMembers(groupId: string, index: number): Promise<platformClient.Models.UserEntityListing> {
    return this.checkLogin() || this.apiGroups.getGroupMembers(groupId, new PagingSpec(100, index));
  }

  //getUserQueues(userId: string, opts?: UsersApi.getUserQueuesOptions): Promise<Models.UserQueueEntityListing>;
  getUserQueues(userId: string, index: number): Promise<platformClient.Models.UserQueueEntityListing> {
    return this.checkLogin() || this.apiUsers.getUserQueues(userId, new PagingSpec(100, index));
  }

  //DATOS REPORTE SERVICE CUSTOMER Y VENTAS
  //postAnalyticsConversationsDetailsQuery(body: platformClient.Models.ConversationQuery): Promise<Models.AnalyticsConversationQueryResponse>;
  postAnalyticsConversationsDetailsQuery_service(from: Date, to: Date, index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var filtrados = new ConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), undefined, null, null, null, new PagingSpec(100, index));
    //console.log(filtrados);
    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsQuery(new ConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), undefined, null, null, null, new PagingSpec(100, index)));
  }

  private getuserDetailQueryFilter(predicate): UserDetailQueryFilter {
    if (predicate === undefined)
      return undefined;
    var userDetailQueryPredicates: UserDetailQueryPredicate[] = [];
    UserDetailQueryPredicateDimension.DIMENSIONS.forEach(dimension => {
      var values = predicate[dimension];
      if (values !== undefined) {
        if (values.length > 0) {
          for (var i = 0; i < values.length; i++) {
            userDetailQueryPredicates.push(new UserDetailQueryPredicate("dimension", dimension, "matches", values[i]));
          }
        } else {
          userDetailQueryPredicates.push(new UserDetailQueryPredicate("dimension", dimension, "exists", undefined));
        }
      }
    });
    return userDetailQueryPredicates.length > 0 ? new UserDetailQueryFilter("or", userDetailQueryPredicates) : undefined;
  }

  private getpresenceDetailQueryFilter(predicate): UserDetailQueryFilter {
    if (predicate === undefined)
      return undefined;
    var userDetailQueryPredicates: UserDetailQueryPredicate[] = [];
    presenceDetailQueryPredicateDimension.DIMENSIONS.forEach(dimension => {
      var values = predicate[dimension];
      if (values !== undefined) {
        if (values.length > 0) {
          for (var i = 0; i < values.length; i++) {
            userDetailQueryPredicates.push(new UserDetailQueryPredicate("dimension", dimension, "matches", values[i]));
          }
        } else {
          userDetailQueryPredicates.push(new UserDetailQueryPredicate("dimension", dimension, "exists", undefined));
        }
      }
    });
    return userDetailQueryPredicates.length > 0 ? new UserDetailQueryFilter("or", userDetailQueryPredicates) : undefined;
  }
  //postAnalyticsUsersDetailsQuery(body: Models.UserDetailsQuery): Promise<Models.AnalyticsUserDetailsQueryResponse>;
  postAnalyticsUsersDetailsQuery(from: Date, to: Date, predicate, index: number): Promise<platformClient.Models.AnalyticsUserDetailsQueryResponse> {
    var promise = this.checkLogin();
    if (promise !== undefined) {
      return promise;
    }

    var userDetailQueryFilter = predicate === undefined || predicate[PredicateDimension.user] === undefined ? undefined : [this.getuserDetailQueryFilter(predicate[PredicateDimension.user])];
    //console.log(userDetailQueryFilter);
    var presenceDetailQueryFilter = predicate === undefined || predicate[PredicateDimension.presence] === undefined ? undefined : [this.getpresenceDetailQueryFilter(predicate[PredicateDimension.presence])];

    var query = new UserDetailsQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), userDetailQueryFilter, presenceDetailQueryFilter, new PagingSpec(100, index));
    //console.log("userDetailQuery, interval: ", query.interval, ", pageNumber", query.paging.pageNumber, query);
    return this.checkLogin() || this.apiUsers.postAnalyticsUsersDetailsQuery(query);
  }

  getUsers(pageSize?: number, index?: number, idUsers?: Array<any>): Promise<platformClient.Models.UserEntityListing> {
    // console.log(idUsers);
    let opts = new getUsersOptions(pageSize, index, idUsers, ['authorization']);
    // console.log(opts);
    return this.checkLogin() || this.apiUsers.getUsers(opts);
  }

  //	postAnalyticsUsersAggregatesQuery(body: Models.UserAggregationQuery): Promise<Models.UserAggregateQueryResponse>;
  postAnalyticsUsersAggregatesQuery(from: Date, to: Date, granularity: string, aUsers: any[]): Promise<platformClient.Models.UserAggregateQueryResponse> {
    //console.log(aUsers);
    var aUserAggregateQueryPredicate: UserAggregateQueryPredicate[] = [];
    var aUserAggregateQueryFilter = null;
    if (aUsers.length > 0) {

      aUsers.forEach((user) => {
        if (user.id === undefined)
          return new Promise((resolve, reject) => {
            reject({ status: '999', code: 'bad.queue', message: 'No se encontro el agente: ' + user.name + '.' });
          });

        aUserAggregateQueryPredicate.push(new UserAggregateQueryPredicate("dimension", "userId", "Matches", user.id));
        //console.log(aUserAggregateQueryPredicate);
      });
      aUserAggregateQueryFilter = new UserAggregateQueryFilter("or", aUserAggregateQueryPredicate);
      // console.log(aUserAggregateQueryFilter);
    }
    var body = new UserAggregationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), granularity, ['userId'], aUserAggregateQueryFilter, ["tAgentRoutingStatus", "tOrganizationPresence", "tSystemPresence"]);
    console.log(body);
    return this.checkLogin() || this.apiUsers.postAnalyticsUsersAggregatesQuery(body);
  }
  //	postAnalyticsConversationsAggregatesQuery(body: Models.ConversationAggregationQuery): Promise<Models.ConversationAggregateQueryResponse>;
  postAnalyticsConversationsAggregatesQuery(from: Date, to: Date, granularity, aUsers: Array<any>): Promise<platformClient.Models.ConversationAggregateQueryResponse> {
    var aConversationAggregateQueryPredicate: ConversationAggregateQueryPredicate[] = [];
    var aConversationAggregateQueryFilter = null;
    if (aUsers.length > 0) {

      aUsers.forEach((user) => {
        if (user.id === undefined)
          return new Promise((resolve, reject) => {
            reject({ status: '999', code: 'bad.queue', message: 'No se encontro el agente: ' + user.name + '.' });
          });
        aConversationAggregateQueryPredicate.push(new ConversationAggregateQueryPredicate("dimension", "userId", "Matches", user.id));
      });
      aConversationAggregateQueryFilter = new UserAggregateQueryFilter("or", aConversationAggregateQueryPredicate);
    }

    var metrics = [
      "nBlindTransferred",
      "nConnected",
      "nConsult",
      "nConsultTransferred",
      "nError",
      "nOffered",
      "nOutbound",
      "nOutboundAbandoned",
      "nOutboundAttempted",
      "nOutboundConnected",
      "nOverSla",
      "nStateTransitionError",
      "nTransferred",
      "oExternalMediaCount",
      "oMediaCount",
      "oServiceLevel",
      "oServiceTarget",
      "tAbandon",
      "tAcd",
      "tAcw",
      "tAgentResponseTime",
      "tAlert",
      "tAnswered",
      "tContacting",
      "tDialing",
      "tFlowOut",
      "tHandle",
      "tHeld",
      "tHeldComplete",
      "tIvr",
      "tMonitoring",
      "tNotResponding",
      "tShortAbandon",
      "tTalk",
      "tTalkComplete",
      "tUserResponseTime",
      "tVoicemail",
      "tWait"
    ]
    var body = new ConversationAggregationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), granularity, ['userId'], aConversationAggregateQueryFilter, metrics);
    console.log(body);
    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsAggregatesQuery(body);
  }

  //getPresencedefinitions(opts?: PresenceApi.getPresencedefinitionsOptions): Promise<Models.OrganizationPresenceEntityListing>;
  getPresencedefinitions(index: number): Promise<platformClient.Models.OrganizationPresenceEntityListing> {

    return this.checkLogin() || this.apiPresences.getPresencedefinitions(new PagingSpec(100, index));

  }

  //getOutboundContactlistContact(contactListId: string, contactId: string): Promise<Models.DialerContact>;
  getOutboundContactlistContact(contactListId: string, contactId: string): Promise<platformClient.Models.DialerContact> {
    var promise = this.checkLogin();
    if (promise !== undefined) {
      return undefined;
    }
    return this.apiOutbound.getOutboundContactlistContact(contactListId, contactId);

  }

}
