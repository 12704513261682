import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Swal from 'sweetalert2';
/*hipotecario calidad seguros*/
import { hipotecarioDataService } from '../../../services/hipotecario/hipotecario-data.service';

import { hipotecarioDataCustomerService } from '../../../services/hipotecario/hipotecario-dataCustomer.service';
import { hipotecarioExcelCustomerService } from '../../../services/hipotecario/hipotecario-excelCustomer.service';

import { hipotecarioDataVentasService } from '../../../services/hipotecario/hipotecario-dataVentas.service';
import { hipotecarioExcelVentasService } from '../../../services/hipotecario/hipotecario-excelVentas.service';

import { hipotecarioDataSiniestrosService } from '../../../services/hipotecario/hipotecario-dataSiniestros.service';
import { hipotecarioExcelSiniestrosService } from '../../../services/hipotecario/hipotecario-excelSiniestros.service';

import { PurecloudService } from '../../../services/purecloud/purecloud.service';
import { PredicateDimension, SegmentDetailQueryPredicateDimension, EvaluationDetailQueryPredicateDimension } from '../../../constants/purecloud';

import { sector } from '../../../models/hipotecario/sector';
import { campania } from '../../../models/hipotecario/campania';
import { agente } from '../../../models/hipotecario/agente';
import { auditor } from '../../../models/hipotecario/auditor';
import { resultado } from '../../../models/hipotecario/resultado';

import { calidad_customer } from 'src/app/models/hipotecario/calidad_customer';
import { calidad_ventas } from 'src/app/models/hipotecario/calidad_ventas';
import { calidad_siniestros } from 'src/app/models/hipotecario/calidad_siniestros';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Models } from 'purecloud-platform-client-v2';

@Component({
  selector: 'app-hipotecario',
  templateUrl: './hipotecario.component.html',
  styleUrls: ['./hipotecario.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HipotecarioComponent implements OnInit {
  pickerFrom_customer;
  myDatePickerFrom_customer: number;
  pickerTo_customer;
  myDatePickerTo_customer: number;
  sector_level: string;
  sector: Array<sector> = [];
  campania: Array<campania> = [];
  resultado: Array<resultado> = [];

  format = { add: 'Agregar', remove: 'Borrar', all: 'Todo', none: 'Ninguno', draggable: true };
  target: Array<campania> = [];
  agente: Array<agente> = [];
  target_agente: Array<agente> = [];
  auditor: Array<auditor> = [];
  target_auditor: Array<auditor> = [];
  target_resultado: Array<resultado> = [];
  minDate: Date;
  maxDate: Date;
  //format: any = DualListComponent.AVAILABLE_LIST_NAME;
  //private dualBox: AngularDualListBoxModule,
  constructor(private hipotecarioDataService: hipotecarioDataService, private hipotecarioDataCustomer: hipotecarioDataCustomerService, private HipotecarioExcelCustomer: hipotecarioExcelCustomerService, private hipotecarioDataVentas: hipotecarioDataVentasService, private HipotecarioExcelVentas: hipotecarioExcelVentasService, private hipotecarioDataSiniestros: hipotecarioDataSiniestrosService, private HipotecarioExcelSiniestros: hipotecarioExcelSiniestrosService, private purecloud: PurecloudService) { }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (this.myDatePickerFrom_customer != undefined) {
      this.maxDate = new Date(this.myDatePickerFrom_customer + 31 * 24 * 60 * 60 * 1000);
    } else {
      this.maxDate = undefined
    }
    if (this.myDatePickerTo_customer != undefined) {
      this.minDate = new Date(this.myDatePickerTo_customer - 31 * 24 * 60 * 60 * 1000);
    } else {
      this.minDate = undefined;
    }
  }

  ngOnInit(): void {
    this.onGeneratesector();
  }

  /**combo sectores: formularios de evaluacion -  */
  onGeneratesector(): void {
    this.hipotecarioDataService.generateSync_sector()
      .then((responseSector: sector[]) =>{
        this.purecloud.getMyGroups()
         .then( (data: Models.UserMe) =>{
          var groupsAvail: boolean[] = [false,false,false]; // Customer, Ventas, Siniestros
          var groupsIds: string[] = [];
          data.groups.forEach( (group) => groupsIds.push(group.id) );
          this.hipotecarioDataService.validateGroups(groupsIds, groupsAvail)
            .then( (responsegroupsAvail: boolean[]) =>{
              var responseSectorFinal: sector[] = [];
              responseSector.forEach( sector => {
                if( sector.viewValue === 'Customer' && responsegroupsAvail[0] ) responseSectorFinal.push(sector);
                if( sector.viewValue === 'Ventas' && responsegroupsAvail[1] ) responseSectorFinal.push(sector);;
                if( sector.viewValue === 'Siniestros' && responsegroupsAvail[2] ) responseSectorFinal.push(sector);;
              });
              responseSector = responseSectorFinal;

              this.onComboSector(responseSector)//traigo toas las colas, sin filtrar por sector
            })
            .catch( (error) => this.thenCatch(error));
         })
         .catch( (error) => this.thenCatch(error));
      })
      .catch( (error) => this.thenCatch(error));
  }




  private onComboSector(response: sector[]): void {
    this.sector = response;
  }


  /*completar comboCampaña()  */
  onGeneratecampania(event): void {   //filtra segun el sector
    if (event.isUserInput) { //controla que funcione el onChnage de forma correcta, sino toma el anterior seleccionado
      //limpiar los combos de campaña, agente y auditor
      this.target = [];
      this.agente = [];
      this.target_agente = [];
      this.auditor = [];
      this.target_auditor = [];
      this.resultado = [];
      this.target_resultado = [];

      // alert(event.source.value);
      // console.log(event.source.viewValue, event.source.selected);
      this.hipotecarioDataService.generateSync_campania(event.source.value)
        .then((response) => this.onComboCampania(response, event.source.value))
        //.then((response) =>  this.onComboCampania(response))//traigo toas las colas, sin filtrar por sector
        .catch(function (response) {
          Swal.fire({
            title: 'Cancelado',
            html: 'Se cancelo la tarea por... <b></b>',
            didOpen: () => {
              Swal.showLoading();
              const content = Swal.getHtmlContainer();
              if (content) {
                const b = content.querySelector('b');
                if (b) {
                  b.textContent = JSON.stringify(response);
                }
              }
            },
            willClose: () => {
              window.location.href = window.location.href
            }
          }).then((result) => {
            console.log(result);
          })
        });

      this.onGenerateAuditor();

    }

  }

  private onComboCampania(response: campania[], formEvaluation_name: string): void {

    this.campania = response;
  }

  /*completar comboAgentes()  */
  onGenerateAgente(event): void {
    if (event.length != 0) {
      this.agente = [];
      if (this.onCtrlSelectedItems(event, 'campania')) {
        //console.log(event);
        //genera los agentes

        event.forEach((queue) => this.hipotecarioDataService.generateSync_agente(queue.value)
          .then((response) => this.onComboAgente(response))
          .catch(function (response) {
            Swal.fire({
              title: 'Cancelado',
              html: 'Se cancelo la tarea por... <b></b>',
              didOpen: () => {
                Swal.showLoading();
                const content = Swal.getHtmlContainer();
                if (content) {
                  const b = content.querySelector('b');
                  if (b) {
                    b.textContent = JSON.stringify(response);
                  }
                }
              },
              willClose: () => {
                window.location.href = window.location.href
              }
            }).then((result) => {
              console.log(result);
            })
          })
        )

        this.onGenerateResultado(event);
      }
    }
    else {
      this.agente = [];
      this.target_agente = [];

      this.resultado = [];
      this.target_resultado = [];
    }


  }

  private onComboAgente(response: agente[]): void {

    this.agente = this.agente.concat(response);

    //elimina repetidos
    let unicos = this.agente.filter((agent) => { return this.agente[agent.value] ? false : (this.agente[agent.value] = true); });
    this.agente = unicos;
    //si existe en target_agente, tamb debo filtrar
    let filter: Array<agente> = [];
    let filter_: Array<agente> = [];
    if (this.target_agente.length > 0) {
      this.target_agente.forEach((a) => {
        filter = unicos.filter((valor) => {
          return valor.value.includes(a.value);
        })
      });

      console.log(filter);
      /*  filter.forEach((a) => {
       filter_ = unicos.filter((valor) => {
           console.log((valor.value.includes(a.value)));
           return valor.value.includes(a.value);
          })
       });  */
    }

    // console.log(filter_);

    this.target_agente = filter;
  }


  /**combo resultado-wrapup que dependen de las queues seleccionadas */
  onGenerateResultado(event): void {
    //alert(event.length);
    if (event.length != 0) {
      //if(this.onCtrlSelectedItems(event)){


      //genera los agentes
      this.resultado = [];
      event.forEach((queue) => this.hipotecarioDataService.generateSync_resultado(queue.value)
        .then((response) => this.onComboResultado(response))
        .catch(function (response) {
          Swal.fire({
            title: 'Cancelado',
            html: 'Se cancelo la tarea por... <b></b>',
            didOpen: () => {
              Swal.showLoading();
              const content = Swal.getHtmlContainer();
              if (content) {
                const b = content.querySelector('b');
                if (b) {
                  b.textContent = JSON.stringify(response);
                }
              }
            },
            willClose: () => {
              window.location.href = window.location.href
            }
          }).then((result) => {
            console.log(result);
          })
        })
      )
      //  }

    }
    else {
      this.resultado = [];
      this.target_resultado = [];
    }
  }

  private onComboResultado(response: resultado[]): void {

    this.resultado = this.resultado.concat(response);

    //elimina repetidos
    let unicos = this.resultado.filter((res) => { return this.resultado[res.value] ? false : (this.resultado[res.value] = true); });
    this.resultado = unicos;
    //si existe en target_agente, tamb debo filtrar
    let filter: Array<resultado> = [];
    let filter_: Array<resultado> = [];
    if (this.target_resultado.length > 0) {
      this.target_resultado.forEach((a) => {
        filter = unicos.filter((valor) => {
          return valor.value.includes(a.value);
        })
      });
    }

    this.target_resultado = filter;
  }

  /*comboAditor()  */
  onGenerateAuditor(): void {
    this.hipotecarioDataService.generateSync_auditor()
      .then((response) => this.onComboAuditor(response))
      .catch(function (response) {
        Swal.fire({
          title: 'Cancelado',
          html: 'Se cancelo la tarea por... <b></b>',
          didOpen: () => {
            Swal.showLoading();
            const content = Swal.getHtmlContainer();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                b.textContent = JSON.stringify(response);
              }
            }
          },
          willClose: () => {
            window.location.href = window.location.href
          }
        }).then((result) => {
          console.log(result);
        })
      })
  }

  private onComboAuditor(response: auditor[]): void {
    this.auditor = response;
  }

  /**controlar la seleccion tope de los filtros */
  onCtrlSelectedItems(event, name: string): boolean {

    if (event.length > 50) {
      Swal.fire(
        'Cancelado Por: ',
        'Seleccionar hasta 50 Items',
        'warning'
      )

      switch (name) {
        case 'auditor': this.target_auditor = []; break;
        case 'campania': this.target = []; break;
        case 'agente': this.target_agente = []; break;
        case 'resultado': this.target_resultado = []; break;

      }
      return false;
    }
    else {
      return true;
    }
  }


  //GENERATE EXCEL calidad (customer, ventas , siniestros)
  async onGenerateReportesCalidad() {
    // alert(this.target.length);
    // if(this.target.length != 0) {

    // console.info('pickerFrom_customer: ' + this.pickerFrom_customer + ', myDatePickerFrom_customer: ' + this.myDatePickerFrom_customer + ', pickerTo_customer: ' + this.pickerTo_customer + ', myDatePickerTo_customer: ' + this.myDatePickerTo_customer);
    if (this.myDatePickerFrom_customer == undefined || this.myDatePickerTo_customer == undefined) {
      return;
    }

    var from = new Date(this.myDatePickerFrom_customer); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to = new Date(this.myDatePickerTo_customer); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'

    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    //Aqui debo pasarle los parametros de los filtros
    //1-obtener filtro de sector

    let sector_level = (this.sector_level.indexOf('Customer') > 0 ? 'Customer' : (this.sector_level.indexOf('Siniestros') > 0 ? 'Siniestros' : (this.sector_level.indexOf('Ventas') > 0 ? 'Ventas' : 'Customer')))
    console.log(sector_level);
    //2-obtener filtro de campañas
    /* aqui si no elige ninguna campaña, por defecto igual tomo las campañas de datatable, no todas las que trae purecloud */

    //var campanias: Array<campania> = (this.target.length != 0) ? this.target : this.campania;
    var campanias: Array<campania> = this.target;
    var campanias_dataTable: Array<campania> = this.campania;
    //alert(campanias);
    //3-obtener el filtro de agentes
    var agentes: Array<agente> = (this.target_agente.length > 0) ? this.target_agente : [];

    //4-obtener el filtro de resultado
    var resultados: Array<resultado> = (this.target_resultado.length > 0) ? this.target_resultado : [];//wrapupcodes

    //5-filtro de evaluador.auditor
    var auditor: Array<auditor> = (this.target_auditor.length > 0) ? this.target_auditor : [];//null porque solo pone la condicion evaluation exits
    let u: Array<any> = [];
    if (auditor.length > 0) {
      auditor.forEach(a => u.push(a.value));
      console.log(u);
    }
    //NO SE PERMITEN MAS DE 100 FILTROS EN UNA CONUSULTA A API
    /*  var iCantFiltrosUsers = Math.ceil(u.length/100);
     var k=0; var iBand = 0;
     let aUsers : Array<any> = [];
     while (k < iCantFiltrosUsers && iBand === 0){
       var ini = k * 100;
       var fin = (u.length - (k+1)*100) < 0 ? u.length : ((k+1)*100 -1);
       //obtiene usuarios
       aUsers = aUsers.concat((await this.purecloud.getUsers(100,1, u.slice(ini,fin))).entities);
       k++;
     }  */

    /* var predicate = {};
    predicate[PredicateDimension.evaluation] = {};
    predicate[PredicateDimension.evaluation][EvaluationDetailQueryPredicateDimension.evaluationId] = [];
    predicate[PredicateDimension.evaluation][EvaluationDetailQueryPredicateDimension.evaluatorId] = u;
    console.log(predicate); */


    switch (sector_level) {
      case 'Customer':
        this.hipotecarioDataCustomer.generateSync_customer(from, to, sector_level, campanias, campanias_dataTable, agentes, resultados, auditor)
          .then((response) => this.processCalidad_Excel_Customer(response, sector_level))
          .catch((response) => {
            Swal.fire({
              title: 'Cancelado',
              html: 'Informe Customer: Se cancelo la tarea por... <b>' + response.text + '</b>',
              didOpen: () => {
                Swal.showLoading();
                const content = Swal.getHtmlContainer();
                if (content) {
                  const b = content.querySelector('b');
                  if (b) {
                    b.textContent = JSON.stringify(response);
                  }
                }
              },
              willClose: () => {
                window.location.href = window.location.href
              }
            }).then((result) => {
              console.log(result);
            })
          });
        break;
      case 'Ventas':
        this.hipotecarioDataVentas.generateSync_ventas(from, to, sector_level, campanias, campanias_dataTable, agentes, resultados, auditor)
          .then((response) => this.processCalidad_Excel_Ventas(response, sector_level))
          .catch((response) => {
            Swal.fire({
              title: 'Cancelado',
              html: 'Se cancelo la tarea por... <b></b>',
              didOpen: () => {
                Swal.showLoading();
                const content = Swal.getHtmlContainer();
                if (content) {
                  const b = content.querySelector('b');
                  if (b) {
                    b.textContent = JSON.stringify(response);
                  }
                }
              },
              willClose: () => {
                window.location.href = window.location.href
              }
            }).then((result) => {
              console.log(result);
            })
          });
        break;
      case 'Siniestros':
        this.hipotecarioDataSiniestros.generateSync_siniestros(from, to, sector_level, campanias, campanias_dataTable, agentes, resultados, auditor)
          .then((response) => this.processCalidad_Excel_Siniestros(response, sector_level))
          .catch((response) => {
            Swal.fire({
              title: 'Cancelado',
              html: 'Se cancelo la tarea por... <b></b>',
              didOpen: () => {
                Swal.showLoading();
                const content = Swal.getHtmlContainer();
                if (content) {
                  const b = content.querySelector('b');
                  if (b) {
                    b.textContent = JSON.stringify(response);
                  }
                }
              },
              willClose: () => {
                window.location.href = window.location.href
              }
            }).then((result) => {
              console.log(result);

            })
          });
        break;
    }
    /*  }
    else
     {
      Swal.fire(
        'Cancelado Por: ',
        'Debes seleccionar al menos una campaña...',
        'warning'
      )

    }   */
  }
  private processCalidad_Excel_Customer(response: calidad_customer[], sector_name: string): void {
    console.log(response);
    this.HipotecarioExcelCustomer.exportExcel(response, sector_name);
  }
  private processCalidad_Excel_Ventas(response: calidad_ventas[], sector_name: string): void {
    console.log(response);
    this.HipotecarioExcelVentas.exportExcel(response, sector_name);
  }
  private processCalidad_Excel_Siniestros(response: calidad_siniestros[], sector_name: string): void {
    //console.log(response);
    this.HipotecarioExcelSiniestros.exportExcel(response, sector_name);
  }

  private thenCatch(response){
    Swal.fire({
      title: 'Cancelado',
      html: 'Se cancelo la tarea por... <b></b>',
      didOpen: () => {
        Swal.showLoading();
        const content = Swal.getHtmlContainer();
        if (content) {
          const b = content.querySelector('b');
          if (b) {
            b.textContent = JSON.stringify(response);
          }
        }
      },
      willClose: () => {
        window.location.href = window.location.href
      }
    }).then((result) => {
      console.log(result);
    })
  }
}
