import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { servicio }  from '../../models/servicios/servicio';

@Injectable({
  providedIn: 'root'
})
export class hipotecarioExcelService {

  constructor() { }
  exportExcel(info : servicio[], from: Date, to: Date) { 
    
    let workbook = new Workbook(); 
    
    var header = ['AGENT NAME', 'TEAM LEADER', 'LOGUEO', 'EN COLA','OCIOSO','NO RESPONDE',
      'FUERA DE COLA','DISPONIBLE','BAÑO','CARGA FUERA DE LINEA','PROBLEMAS DE SISTEMA',
      'LLAMADA MANUAL','BACK OFFICE','BUSY','AWAY','BREAK','COMIDA','SYSTEM AWAY','REUNION',
      'CAPACITACION','INTERACTUANDO','OCCUPANCY','ALERT','ALERTA-NO ATIENDE','ATENDIDA',
      'SALIENTES','MANEJADA','TIEMPO HABLADO','TOTAL HOLD','TOTAL ACW','PROMEDIO ACW',
      'PROMEDIO HOLD','PROMEDIO HABLADO','ASIGNADO A TL','PREMIO/INCENTIVO'];
    var name = 'reporte_servicios_hipotecario';
    let worksheet = workbook.addWorksheet('Reporte de Servicios');
    var fila = worksheet.addRow([]);
    fila.height = 30;       
    fila.font = { name: 'Arial', family: 4, size: 8, bold: true};//underline: 'double', 
    fila.getCell('A').value = 'REPORTE DE SERVICIOS';
    fila.getCell('A').style.alignment = {horizontal: 'center'};
    fila.getCell('A').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
          argb: '00939b9e'
      }
    };
    worksheet.mergeCells('A1:AI1'); 
    worksheet.getRow(1).alignment = { horizontal: 'center',vertical: 'justify'};
   
    //fila 2: subtitulos
    var filaEncabezado = worksheet.addRow(header); 
    filaEncabezado.height = 50;
    filaEncabezado.font = { name: 'Arial', family: 4, size: 8, bold: true };
    filaEncabezado.alignment = { horizontal: 'center' };

    // Apply fill to each cell in the row
    header.forEach((_, index) => {
        worksheet.getCell(2, index + 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00D3D7D8' }
        };
    });
   
    var counter = {
      title: 'Total general',
      count: 0,
      sum: 0
    };     
    info.forEach(d => this.addRow(d, worksheet));
  /*   var row = worksheet.addRow([counter.title, counter.sum]);
    row.height = 20;
    row.font = {name: 'Tahoma', size: 7, bold: true}; */

  //AGENT NAME
  worksheet.getColumn(1).width = 30;
  filaEncabezado.getCell(1).alignment = { horizontal: 'center', wrapText: true};
  //TEAM LEADER
  worksheet.getColumn(2).width = 20;
  filaEncabezado.getCell(2).alignment = { horizontal: 'center', wrapText: true};
  //LOGUEO
  worksheet.getColumn(3).width = 10;
  filaEncabezado.getCell(3).alignment = { horizontal: 'center', wrapText: true};
  //EN COLA
  worksheet.getColumn(4).width = 10;
  filaEncabezado.getCell(4).alignment = { horizontal: 'center', wrapText: true};
    //OCIOSO
    worksheet.getColumn(5).width = 10;
    filaEncabezado.getCell(5).alignment = { horizontal: 'center', wrapText: true};

  //NO RESPONDE
  worksheet.getColumn(6).width = 10;
  filaEncabezado.getCell(6).alignment = { horizontal: 'center', wrapText: true};

  //FUERA DE COLA
  worksheet.getColumn(7).width = 10;
  filaEncabezado.getCell(7).alignment = { horizontal: 'center', wrapText: true};

  //DISPONIBLE
  worksheet.getColumn(8).width = 10;
  filaEncabezado.getCell(8).alignment = { horizontal: 'center', wrapText: true};

  //BAÑO
  worksheet.getColumn(9).width = 10;
  filaEncabezado.getCell(9).alignment = { horizontal: 'center', wrapText: true};
  //CARGA FUERA DE LINEA
  worksheet.getColumn(10).width = 15;
  filaEncabezado.getCell(10).alignment = { horizontal: 'center', wrapText: true};
  //PROBLEMAS DEL SISTEMA
  worksheet.getColumn(11).width = 15;
  filaEncabezado.getCell(11).alignment = { horizontal: 'center', wrapText: true};
  //LLAMADA MANUAL
  worksheet.getColumn(12).width = 15;
  filaEncabezado.getCell(12).alignment = { horizontal: 'center', wrapText: true};
  //BACK OFFICE
  worksheet.getColumn(13).width = 15;
  filaEncabezado.getCell(13).alignment = { horizontal: 'center', wrapText: true};

  
  //OCUPADO
  worksheet.getColumn(14).width = 10;
  filaEncabezado.getCell(14).alignment = { horizontal: 'center', wrapText: true};

    //AWAY
  worksheet.getColumn(15).width = 10;
  filaEncabezado.getCell(15).alignment = { horizontal: 'center', wrapText: true};
  //BREAK
  worksheet.getColumn(16).width = 10;
  filaEncabezado.getCell(16).alignment = { horizontal: 'center', wrapText: true};
  //COMIDA
  worksheet.getColumn(17).width = 10;
  filaEncabezado.getCell(17).alignment = { horizontal: 'center', wrapText: true};
  //SYSTEM AWAY
  worksheet.getColumn(18).width = 10;
  filaEncabezado.getCell(18).alignment = { horizontal: 'center', wrapText: true};
 //REUNION
  worksheet.getColumn(19).width = 10;
  filaEncabezado.getCell(19).alignment = { horizontal: 'center', wrapText: true};
  //CAPACITACION
  worksheet.getColumn(20).width = 15;
  filaEncabezado.getCell(20).alignment = { horizontal: 'center', wrapText: true};
  //INTERACTUANDO
  worksheet.getColumn(21).width = 15;
  filaEncabezado.getCell(21).alignment = { horizontal: 'center', wrapText: true};
  //OCCUPANCY
  worksheet.getColumn(22).width = 10;
  filaEncabezado.getCell(22).alignment = { horizontal: 'center', wrapText: true};
  //ALERT
  worksheet.getColumn(23).width = 10;
  filaEncabezado.getCell(23).alignment = { horizontal: 'center', wrapText: true};
 //ALERTA NO ATIENDE
  worksheet.getColumn(24).width = 10;
  filaEncabezado.getCell(24).alignment = { horizontal: 'center', wrapText: true};
  //ATENDIDA
  worksheet.getColumn(25).width = 10;
  filaEncabezado.getCell(25).alignment = { horizontal: 'center', wrapText: true};
  //SALIENTES
  worksheet.getColumn(26).width = 10;
  filaEncabezado.getCell(26).alignment = { horizontal: 'center', wrapText: true};
  //MANEJADAS
  worksheet.getColumn(27).width = 10;
  filaEncabezado.getCell(27).alignment = { horizontal: 'center', wrapText: true};
  //TIEMPO HABLADO
  worksheet.getColumn(28).width = 10;
  filaEncabezado.getCell(28).alignment = { horizontal: 'center', wrapText: true};
 //TOTAL HOLD
  worksheet.getColumn(29).width = 10;
  filaEncabezado.getCell(29).alignment = { horizontal: 'center', wrapText: true};
  //TOTAL ACW
  worksheet.getColumn(30).width = 10;
  filaEncabezado.getCell(30).alignment = { horizontal: 'center', wrapText: true};
  //LOGIN
   worksheet.getColumn(31).width = 10;
   filaEncabezado.getCell(31).alignment = { horizontal: 'center', wrapText: true};
  //PROMEDIO ACW
   worksheet.getColumn(32).width = 10;
   filaEncabezado.getCell(32).alignment = { horizontal: 'center', wrapText: true};
   //PROMEDIO HOLD
   worksheet.getColumn(33).width = 10;
   filaEncabezado.getCell(33).alignment = { horizontal: 'center', wrapText: true};
  //PROMEDIO HABLADO
  worksheet.getColumn(34).width = 10;
  filaEncabezado.getCell(34).alignment = { horizontal: 'center', wrapText: true};

  //ASIGNADO A TL
  worksheet.getColumn(35).width = 15;
  filaEncabezado.getCell(35).alignment = { horizontal: 'center', wrapText: true};

  //PREMIO/INCENTIVO
  worksheet.getColumn(36).width = 15;
  filaEncabezado.getCell(36).alignment = { horizontal: 'center', wrapText: true};

  workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, name + '.xlsx');
      });
  }

  private async addRow(info : servicio, worksheet: Worksheet) {
   // console.log(info);
    var row = worksheet.addRow([info.agenteName,info.teamLeaderName,info.timeAgentetLogueo,info.timeAgenteEnCola, info.timeAgenteOcioso,info.timeAgenteNoResponde,info.timeAgenteFueraCola, info.timeAgenteDisponible,info.timeAgenteBanio,info.timeAgenteCargaFueraLinea,info.timeAgenteProblemasSistema,info.timeAgenteLlamadaManual,info.timeAgenteBackOffice, info.timeAgenteOcupado,info.timeAgenteAway,info.timeAgenteBreak,info.timeAgenteComida,info.timeAgenteSystemAway,info.timeAgenteReunion,info.timeAgenteCapacitacion,info.timeAgenteInteractuando,info.porcentajeOccupancy,info.cantAlert,info.cantAlertNoAtiende,info.cantAtendida,info.cantSaliente,info.cantManejadas,info.timeAgenteTalk,info.timeAgenteHold,info.timeAgenteACW,info.promedioACW,info.promedioHold, info.promedioTalk, info.timeAgenteAsignadoATL, info.timeAgentePremioIncentivo]);
    row.height = 20; 
    row.font = {name: 'Calibri', size: 8};
  } 
  
}
