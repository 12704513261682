import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as platformClient from 'purecloud-platform-client-v2';
import Swal from 'sweetalert2';

import { grupo } from '../../../models/servicios/grupo';
import { queue } from '../../../models/servicios/queue';
import { agente } from '../../../models/hipotecario/agente';
import { listbox } from '../../../models/servicios/listbox';
import { servicio } from '../../../models/servicios/servicio';
import { PredicateDimension, SegmentDetailQueryPredicateDimension, UserDetailQueryPredicateDimension } from '../../../constants/purecloud';

//import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension }  from '../../../constants/purecloud';
import { serviciosDataService } from '../../../services/servicios/servicios-data.services';
import { hipotecarioExcelService } from '../../../services/servicios/servicios-data.excel';

import { UsersSessionsDataService } from '../../../services/servicios/users-sessions-data.service';
import { UsersSessionsCloudService } from '../../../services/servicios/users-sessions-cloud.service';

import { UsersDetailDataService } from '../../../services/servicios/users-detail-data.service';

import { PurecloudService } from '../../../services/purecloud/purecloud.service';

import { userDetail } from '../../../models/purecloud/userDetail';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HipotecarioServices implements OnInit {

  pickerFrom_serviceCustomer;
  myDatePickerFrom_serviceCustomer: number;
  pickerTo_serviceCustomer;
  myDatePickerTo_serviceCustomer: number;
  //combos
  format = { add: 'Agregar', remove: 'Borrar', all: 'Todo', none: 'Ninguno', draggable: true };
  grupo: Array<grupo> = [];
  destino_grupo: Array<grupo> = [];

  queue: Array<queue> = [];
  destino_queue: Array<queue> = [];

  agente: Array<agente> = [];
  destino_agente: Array<agente> = [];

  listbox: Array<listbox> = [];

  constructor(private dataService: UsersSessionsDataService, private userDetail: UsersDetailDataService, private serviciosDataService: serviciosDataService, private hipotecarioExcelService: hipotecarioExcelService, private cloudService: UsersSessionsCloudService, private purecloud: PurecloudService) { }
  //private serviciosDataService: serviciosDataService
  ngOnInit(): void {
    this.onGenerategrupo();
    this.onGenerateQueue();
    this.onGenerateAgente([], 2);

  }

  /**combo grupo.-  */
  onGenerategrupo(): void {
    this.serviciosDataService.generateSync_grupo()
      .then((response) => this.onComboGrupo(response))
      .catch((response) => this.error_catch(response));
  }

  private onComboGrupo(response: grupo[]): void {
    // console.log(response);
    let filter = response.filter((res) => { return res.viewValue.includes('Agente') });
    this.grupo = filter;
  }

  /**combo queue.-  */
  onGenerateQueue(): void { //filtra por grupo
    this.serviciosDataService.generateSync_queue()
      .then((response) => this.onComboQueue(response))
      .catch((response) => this.error_catch(response))

  }

  private onComboQueue(response: queue[]): void {
    // console.log(response);
    this.queue = this.queue.concat(response);
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  private sleep_aux() {
  }
  private error_catch(response) {
    console.log('Error', response);
    Swal.fire({
      title: 'Cancelado',
      html: 'Se cancelo la tarea por... <b></b>',
      didOpen: () => {
        Swal.showLoading();
        const content = Swal.getHtmlContainer();
        if (content) {
          const b = content.querySelector('b');
          if (b) {
            b.textContent = JSON.stringify(response);
          }
        }
      },
      willClose: () => {
        window.location.href = window.location.href
      }
    }).then((result) => {
      console.log(result);
    })
  }

  private async generateSync_agentes_origenId(filtro, origenId, destino_grupo, destino_queue, agentes) {
    console.log('Filtro', filtro);
    await this.serviciosDataService.generateSync_agentes(filtro, origenId)
      .then((response) => this.onComboAgente(response, destino_grupo, destino_queue, origenId, agentes))
      .catch((response) => this.error_catch(response));
    this.sleep(100);
  }
  private async generateSync_agentes_0(filtro, destino_grupo, destino_queue, agentes) {
    console.log('Filtro', filtro);
    await this.serviciosDataService.generateSync_agentes(filtro, 0)
      .then((response) => this.onComboAgente(response, destino_grupo, destino_queue, 0, agentes))
      .catch((response) => this.error_catch(response));
    this.sleep(100);
  }
  private async generateSync_agentes_1(filtro, destino_grupo, destino_queue, agentes) {
    console.log('Filtro', filtro);
    await this.serviciosDataService.generateSync_agentes(filtro, 1)
      .then((response) => this.onComboAgente(response, destino_grupo, destino_queue, 1, agentes))
      .catch((response) => this.error_catch(response));
    this.sleep(100);
  }
  private async generateSync_agentes_0_origenId(filtro, destino_grupo, destino_queue, origenId, agentes) {
    console.log('Filtro', filtro);
    await this.serviciosDataService.generateSync_agentes(filtro, 0)
      .then((response) => this.onComboAgente(response, destino_grupo, destino_queue, origenId, agentes))
      .catch((response) => this.error_catch(response));
    this.sleep(100);
  }
  private async generateSync_agentes_1_origenId(filtro, destino_grupo, destino_queue, origenId, agentes) {
    console.log('Filtro', filtro);
    await this.serviciosDataService.generateSync_agentes(filtro, 1)
      .then((response) => this.onComboAgente(response, destino_grupo, destino_queue, origenId, agentes))
      .catch((response) => this.error_catch(response));
    this.sleep(100);
  }
  async onGenerateAgente(event, origenId) {
    //console.log(this.destino_queue);
    var destino_grupo: Array<grupo> = this.destino_grupo;
    var destino_queue: Array<queue> = this.destino_queue;
    //console.log(destino_queue.length);
    var agentes: Array<agente> = this.agente;
    //console.log(agentes);
    if (event.length != 0) {

      this.agente = [];
      if (this.onCtrlSelectedItems(event, (origenId === 0) ? 'grupo' : 'queue')) {
        console.log('General', event);
        for (let i = 0; i < event.length; i++) {
          var filtro = event[i];
          await this.generateSync_agentes_origenId(filtro.value, origenId, destino_grupo, destino_queue, agentes);
        }
        //event.forEach((filtro) => this.generateSync_agentes_origenId(filtro.value, origenId, destino_grupo, destino_queue, agentes));
        if (((origenId === 0) && (destino_queue.length > 0))) {
          console.log('Queue', destino_queue);
          for (let i = 0; i < destino_queue.length; i++) {
            var filtroQueue = destino_queue[i];
            await this.generateSync_agentes_1(filtroQueue.value, destino_grupo, destino_queue, agentes);
          }
          //destino_queue.forEach((filtro) => this.generateSync_agentes_1(filtro.value, destino_grupo, destino_queue, agentes));
        }
        if (((origenId === 1) && (destino_grupo.length > 0))) {
          console.log('Grupo', destino_grupo);
          for (let i = 0; i < destino_grupo.length; i++) {
            var filtroGrupo = destino_grupo[i];
            await this.generateSync_agentes_0(filtroGrupo.value, destino_grupo, destino_queue, agentes);
          }
          //destino_grupo.forEach((filtro) => this.generateSync_agentes_0(filtro.value, destino_grupo, destino_queue, agentes));
        }
      }
    }
    else {
      if (this.onCtrlSelectedItems(event, (origenId === 0) ? 'grupo' : 'queue')) {
        if ((origenId === 2 && (destino_queue.length === 0) && (destino_grupo.length === 0)) || (origenId === 0 && (destino_queue.length === 0)) || (origenId === 1 && (destino_grupo.length === 0))) {
          this.agente = [];
          await this.serviciosDataService.generateSync_agentes(null, 2)
            .then((response) => this.onComboAgente(response, destino_grupo, destino_queue, origenId, agentes))
            .catch((response) => this.error_catch(response));
          this.sleep(100);
        }
        else {
          if (((origenId === 0) && (destino_queue.length > 0)) || ((origenId === 0) && (destino_queue.length > 0) && (destino_grupo.length > 0))) {
            this.agente = [];
            console.log('Queue', destino_queue);
            for (let i = 0; i < destino_queue.length; i++) {
              var filtroQueue = destino_queue[i];
              await this.generateSync_agentes_1_origenId(filtroQueue.value, destino_grupo, destino_queue, origenId, agentes);
            }
            //destino_queue.forEach((filtro) => this.generateSync_agentes_1_origenId(filtro.value, destino_grupo, destino_queue, origenId, agentes));
          }
          if ((origenId === 1 && (destino_grupo.length > 0)) || ((origenId === 1) && (destino_queue.length > 0) && (destino_grupo.length > 0))) {
            this.agente = [];
            console.log('Grupo', destino_grupo);
            for (let i = 0; i < destino_grupo.length; i++) {
              var filtroGrupo = destino_grupo[i];
              await this.generateSync_agentes_0_origenId(filtroGrupo.value, destino_grupo, destino_queue, origenId, agentes);
            }
            //destino_grupo.forEach((filtro) => this.generateSync_agentes_0_origenId(filtro.value, destino_grupo, destino_queue, origenId, agentes));
          }
        }
      }
    }
  }


  private onComboAgente(response: agente[], destino_grupo: Array<grupo>, destino_queue: Array<queue>, origenId: number, agentes: Array<agente>): void {
    this.agente = this.agente.concat(response);
    //elimina repetidos
    let unicos = this.agente.filter((agent) => { return this.agente[agent.value] ? false : (this.agente[agent.value] = true); });
    this.agente = unicos;
    //si existe en target_agente, tamb debo filtrar
    let filter: Array<agente> = [];
    let filter_: Array<agente> = [];
    if (this.destino_agente.length > 0) {
      this.destino_agente.forEach((a) => {
        filter = unicos.filter((valor) => {
          //console.log(valor.value.includes(a.value));
          return valor.value.includes(a.value);
        })
      });
    }
    this.destino_agente = filter;
  }

  /**controlar la seleccion tope de los filtros */
  onCtrlSelectedItems(event, name: string): boolean {

    if (event.length > 50) {
      Swal.fire(
        'Cancelado Por: ',
        'Seleccionar hasta 50 Items',
        'warning'
      )

      switch (name) {
        case 'grupo': this.destino_grupo = []; break;
        case 'queue': this.destino_queue = []; break;
        case 'agente': this.destino_agente = []; break;
      }
      return false;
    }
    else {
      return true;
    }
  }

  //onGenerateReportesServices():void {
  async onGenerateReportesServices() {
    console.info('pickerFrom_serviceCustomer: ' + this.pickerFrom_serviceCustomer + ', myDatePickerTo_serviceCustomer: ' + this.myDatePickerFrom_serviceCustomer + ', pickerTo_serviceCustomer: ' + this.pickerTo_serviceCustomer + ', myDatePickerTo_serviceCustomer: ' + this.myDatePickerTo_serviceCustomer);
    if (this.myDatePickerFrom_serviceCustomer == undefined || this.myDatePickerTo_serviceCustomer == undefined) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var from = new Date(this.myDatePickerFrom_serviceCustomer); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to = new Date(this.myDatePickerTo_serviceCustomer); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'

    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    //1-agentes
    // let aUsers = (await this.cloudService.getUsers()).entities;
    var destino_agente: Array<agente> = this.destino_agente;
    var agente: Array<agente> = this.agente;

    let u: Array<any> = [];
    (destino_agente.length > 0) ? destino_agente.forEach(a => u.push(a.value)) : agente.forEach(a => u.push(a.value));
    //console.log(u);

    //NO SE PERMITEN MAS DE 100 FILTROS EN UNA CONUSULTA A API
    var iCantFiltrosUsers = Math.ceil(u.length / 100);
    var k = 0; var iBand = 0;

    //OBTENER LAS PRESENCES ORGANIZATION
    let organizationPresences = (await this.purecloud.getPresencedefinitions(1)).entities;
    console.log(organizationPresences);

    let aUsers: Array<any> = [];

    while (k < iCantFiltrosUsers && iBand === 0) {
      var ini = k * 100;
      var fin = (u.length - (k + 1) * 100) < 0 ? u.length : ((k + 1) * 100 - 1);
      //obtiene usuarios
      aUsers = aUsers.concat((await this.purecloud.getUsers(100, 1, u.slice(ini, fin))).entities);
      k++;
      //console.log(aUsers);
    }
    console.log(aUsers.length);
    let aUsersComplete = (await this.purecloud.getUsers(400, 1)).entities;

    var iCantLlamadas = Math.ceil(aUsers.length / 100);
    let aUserStatusObservation: Array<any> = [];
    var i = 0; var iBand = 0;
    while (i < iCantLlamadas && iBand === 0) {
      var ini = i * 100;
      var fin = (aUsers.length - (i + 1) * 100) < 0 ? aUsers.length : ((i + 1) * 100 - 1);
      //aUserStatusObservation = aUserStatusObservation.concat(await this.purecloud.postAnalyticsUsersAggregatesQuery(from, to, aUsers.slice(ini,fin)));
      aUserStatusObservation = aUserStatusObservation.concat(await this.purecloud.postAnalyticsUsersAggregatesQuery(from, to, undefined, aUsers.slice(ini, fin))
        .then((queryResponse) => { return queryResponse })
        .catch((response) => {
          iBand = 1;
          Swal.fire(
            response.status,
            response.text,
            'error'
          )
        })
      );
      i++;
      console.log(aUserStatusObservation);
    }

    let aConversationAggregate: Array<any> = [];

    var j = 0; var iBand_ = 0;
    while (j < iCantFiltrosUsers && iBand_ === 0) {
      var ini = j * 100;
      var fin = (aUsers.length - (j + 1) * 100) < 0 ? aUsers.length : ((j + 1) * 100 - 1);
      aConversationAggregate = aConversationAggregate.concat(await this.purecloud.postAnalyticsConversationsAggregatesQuery(from, to, undefined, aUsers.slice(ini, fin))
        .then((queryResponse) => { return queryResponse })
        .catch((response) => {
          iBand_ = 1;
          Swal.fire(
            response.status,
            response.text,
            'error'
          )
        })
      );
      console.log(aConversationAggregate);
      j++;
    }

    /*  var predicate = {};
     predicate[PredicateDimension.segment] = {};
     predicate[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.userId] = [aUsers];
     let aConversationsDetail:Array<any> = await this.dataService.generate(from, to, predicate,false);
     console.log(aConversationsDetail); */

    /*var predicate = {};
    predicate[PredicateDimension.user] = {};
    predicate[PredicateDimension.user][UserDetailQueryPredicateDimension.userId] = ['b2060fd3-7512-49cd-ae25-ecc3cff50d15'];
    let aUsersDetail:Array<any> = await this.userDetail.generate(from, to, predicate,true);
    console.log(aUsersDetail); */
    if ((aUserStatusObservation !== undefined && aUserStatusObservation.length > 0) && (aConversationAggregate !== undefined && aConversationAggregate.length > 0) && aUsers !== undefined) {
      //aqui llamar funcion que genera excel
      this.processServices_Excel(organizationPresences, aUsersComplete, aUserStatusObservation, aConversationAggregate, aUsers, from, to);

    }
    else {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b></b>',
        didOpen: () => {
          Swal.showLoading();
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = JSON.stringify(aUserStatusObservation);
            }
          }
        },
        willClose: () => {
          window.location.href = window.location.href
        }
      }).then((result) => {
        console.log(result);
      })
    }

  }
  private processServices_Excel(organizationPresences: Array<any>, aUsersComplete: Array<any>, aUserStatusObservation: Array<any>, aConversationAggregate: Array<any>, aUsers: platformClient.Models.User[], from: Date, to: Date): void {
    //console.log(Object.entries(aUserStatusObservation).length === 0);
    var aServicio: Array<servicio> = [];
    aUsers.forEach((user) => {
      var Servicio = new servicio();

      //let aUserStatus = undefined;
      aUserStatusObservation.forEach((usersStatus) => {
        usersStatus.results.filter((u) => {
          if (u.group.userId === user.id) {
            //el usuario existe, traer datos del usuario
            if (user.manager != undefined) {
              let datosManager = aUsersComplete.filter(u => { return u.id.includes(user.manager.id); });
              datosManager.forEach(manager => {

                Servicio.agenteId = user.id;
                Servicio.agenteName = user.name;
                Servicio.teamLeaderId = manager.id;
                Servicio.teamLeaderName = manager.name;

              })
            }
            Servicio.agenteId = user.id;
            Servicio.agenteName = user.name;
            //datos de agente exist
            //siempre es offqueue a menos q este en cola: presence: avalaible, away, busy,idle,meal,training, meeting,break
            var tOnQueue = 0;
            var tOffQueue = 0;
            var tInteracting = 0;
            var tOcupado = 0;
            u.data.forEach((m) => {
              m.metrics.forEach((met) => {
                switch (met.qualifier) {
                  case 'AVAILABLE':
                    Servicio.timeAgenteDisponible = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    tOffQueue += met.stats.sum;
                    break;
                  // case 'AWAY':
                  //   Servicio.timeAgenteAway = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                  //   tOffQueue += met.stats.sum;
                  //   break;
                  case 'SYSTEM AWAY':
                    Servicio.timeAgenteSystemAway = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    break;
                  case 'BUSY':
                    Servicio.timeAgenteOcupado = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    tOffQueue += met.stats.sum;
                    tOcupado = met.stats.sum;
                    break;
                  case 'OFFLINE':
                    Servicio.timeAgenteOffLine = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    break;
                  case 'IDLE': //corresponde al idle de routing, por eso no lo tengo en cuenta para off_queue
                    Servicio.timeAgenteOcioso = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    break;
                  case 'ON_QUEUE':
                    Servicio.timeAgenteEnCola = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    tOnQueue = met.stats.sum;
                    break;
                  case 'MEAL':
                    Servicio.timeAgenteComida = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    tOffQueue += met.stats.sum;
                    break;
                  case 'TRAINING':
                    Servicio.timeAgenteCapacitacion = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    tOffQueue += met.stats.sum;
                    break;
                  case 'MEETING':
                    Servicio.timeAgenteReunion = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    tOffQueue += met.stats.sum;
                    break;
                  case 'BREAK':
                    Servicio.timeAgenteBreak = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    tOffQueue += met.stats.sum;
                    break;
                  case 'COMMUNICATING':
                    Servicio.timeAgenteDisponible = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    break;
                  /*  case 'IDLE':
                        Servicio.timeAgenteOcioso = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                   break; */
                  case 'INTERACTING':
                    Servicio.timeAgenteInteractuando = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    tInteracting = met.stats.sum;
                    break;
                  case 'NOT_RESPONDING':
                    Servicio.timeAgenteNoResponde = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                    break;
                  default: //corresponde a "metric": "tOrganizationPresence"
                    organizationPresences.filter(orgPresence => {
                      if (orgPresence.id === met.qualifier) {
                        console.log(orgPresence.languageLabels.en);
                        switch (orgPresence.languageLabels.en) {
                          case 'Baño':
                            Servicio.timeAgenteBanio = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            break;
                          case 'Carga Fuera de Linea':
                            Servicio.timeAgenteCargaFueraLinea = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            break;
                          case 'Problemas de Sistema':
                            Servicio.timeAgenteProblemasSistema = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            break;
                          case 'Llamada Manual':
                            Servicio.timeAgenteLlamadaManual = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            break;
                          case 'Back Office':
                            Servicio.timeAgenteBackOffice = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            break;
                          case 'Asignado por TL':
                          case 'Asignado a TL':
                            Servicio.timeAgenteAsignadoATL = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            break;
                          case 'Premio / Incentivo':
                            Servicio.timeAgentePremioIncentivo = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            break;
                        }
                      }
                    })
                    break;
                }
              })
              Servicio.timeAgenteFueraCola = this.parseMillisecondsIntoReadableTime(tOffQueue);
              Servicio.timeAgentetLogueo = this.parseMillisecondsIntoReadableTime(tOnQueue + tOffQueue);
            })
            //let conversationAgregate = undefined;
            aConversationAggregate.forEach((conversationAgregate) => {
              //console.log( conversationAgregate.results);
              if (conversationAgregate.results !== undefined) {
                conversationAgregate.results.filter((c) => {
                  var cantTalkCompletas = 0;
                  var sumHeld = 0;
                  var sumTalk = 0;
                  var sumTalkCompletas = 0;
                  var cantACW = 0;
                  if (c.group.userId === user.id && c.group.mediaType === 'voice') {
                    c.data.forEach((m) => {
                      m.metrics.forEach((met) => {
                        switch (met.metric) {
                          case 'tAlert': Servicio.cantAlert = met.stats.count;
                            break;
                          case 'tAnswered': Servicio.cantAtendida = met.stats.count;
                            break;
                          case 'tNotResponding': Servicio.cantAlertNoAtiende = met.stats.count;
                            break;
                          case 'tHandle': Servicio.cantManejadas = met.stats.count;
                            break;
                          case 'nOutbound': Servicio.cantSaliente = met.stats.count;
                            break;
                          case 'tTalk': Servicio.timeAgenteTalk = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            sumTalk = met.stats.sum;
                            break;
                          case 'tHeld': Servicio.timeAgenteHold = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            sumHeld = met.stats.sum;
                            break;
                          case 'tTalkComplete': cantTalkCompletas = met.stats.count;
                            sumTalkCompletas = met.stats.sum;
                            break;
                          case 'tAcw':
                            Servicio.timeAgenteACW = this.parseMillisecondsIntoReadableTime(met.stats.sum);
                            Servicio.promedioACW = (this.parseMillisecondsIntoReadableTime(met.stats.sum / met.stats.count) !== undefined) ? this.parseMillisecondsIntoReadableTime(met.stats.sum / met.stats.count) : null;
                            cantACW = met.stats.count

                            break;
                        }
                      })
                      Servicio.promedioTalk = (this.parseMillisecondsIntoReadableTime(sumTalk / cantACW) !== undefined) ? this.parseMillisecondsIntoReadableTime(sumTalk / cantACW) : null;
                      Servicio.promedioHold = (this.parseMillisecondsIntoReadableTime(sumHeld / cantACW) !== undefined) ? this.parseMillisecondsIntoReadableTime(sumHeld / cantACW) : null;
                      var ocupancy = (Math.round((tInteracting / tOnQueue) * 100) !== undefined) ? Math.round((tInteracting / tOnQueue) * 100) : null;

                      Servicio.porcentajeOccupancy = ocupancy.toString() + ' %';

                    })
                  }
                });

              }

            });


            aServicio.push(Servicio);
          }
        });
      });
    });
    this.hipotecarioExcelService.exportExcel(aServicio, from, to);
  }

  public parseMillisecondsIntoReadableTime(milliseconds: number): string {
    //Get hours from milliseconds
    var hours = milliseconds / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;


    return h + ':' + m + ':' + s;
  }

  /* public timeConversion(millisec: number) {

    var seconds = (millisec / 1000).toFixed(1);

    var minutes = (millisec / (1000 * 60)).toFixed(1);

    var hours = (millisec / (1000 * 60 * 60)).toFixed(1);

    var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

    if (seconds < 60) {
        return seconds + " Sec";
    } else if (minutes < 60) {
        return minutes + " Min";
    } else if (hours < 24) {
        return hours + " Hrs";
    } else {
        return days + " Days"
    }
  }*/


}
