import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from  '@angular/router';
import { TranslateService } from "@ngx-translate/core"
import { Models } from 'purecloud-platform-client-v2';
import { PurecloudService }  from './services/purecloud/purecloud.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'reports';
  public grupoOtros: string;
  public grupoCalidad: string;

  constructor(private translate: TranslateService, private purecloud: PurecloudService, private router : Router) {
    this.translate.setDefaultLang('es');
    this.translate.use(this.translate.getBrowserLang());
  }
  ngOnInit(): void {
   this.purecloud.login()
     .then( (response) => this.succesfull())
     .catch( (response) => this.thenCatch(response));
  }

  private succesfull(){
    this.purecloud.getMyGroups()
    .then( (data: Models.UserMe) =>{
      var groupsIds: string[] = [];
      data.groups.forEach( (group) => {
        groupsIds.push(group.id);
      });
      this.setGroups(groupsIds);
    })
    .catch( error =>{ console.log(error)});
  }

  private setGroups(groupsIds: Array<string>){
    var groupApi = this.purecloud.getGroupsApi();
    groupsIds.forEach( id => {
      groupApi.getGroup(id)
      .then( group =>{
        var groupName = ( group.name !== undefined) ? group.name : 'NA';
        switch(groupName) {
          case 'Reporteria Itx':
            this.grupoOtros = 'OTROS'
            break;
          case 'Reporteria Calidad Itx':
            this.grupoCalidad = 'CALIDAD';
            break;
          case 'Reporteria Calidad Siniestros Itx':
            this.grupoCalidad = 'CALIDAD';
            break;
          case 'Reporteria Calidad Customer Itx':
            this.grupoCalidad = 'CALIDAD';
            break;
          case 'Reporteria Calidad Ventas Itx':
            this.grupoCalidad = 'CALIDAD';
            break;

        }
      })
    });

  }

  private thenCatch(response){
    Swal.fire({
      title: 'Cancelado',
      html: 'Se cancelo la tarea por... <b></b>',
      didOpen: () => {
        Swal.showLoading();
        const content = Swal.getHtmlContainer();
        if (content) {
          const b = content.querySelector('b');
          if (b) {
            b.textContent = JSON.stringify(response);
          }
        }
      },
      willClose: () => {
        window.location.href = window.location.href
      }
    }).then((result) => {
      console.log(result);
    })
  }
}
