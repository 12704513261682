import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { userDetail } from 'src/app/models/purecloud/userDetail';
import Swal from 'sweetalert2'
import { TimeRange }  from '../../purecloud/time-range';
import { PurecloudService } from '../../purecloud/purecloud.service';

@Injectable({
    providedIn: 'root'
  })
export abstract class userDetailDataPurecloudService {
    public Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1000
    });
  
constructor(private PurecloudService : PurecloudService) { }

protected abstract processInternalCall(UserDetail: platformClient.Models.AnalyticsUserDetail, timeRange : TimeRange, calls : Object[]);  
public abstract processCall(UserDetail : userDetail, calls : Object[]);

public generate(from: Date, to: Date, predicate, isSync: boolean) : Promise<Object[]> {
    let promise = new Promise<Object[]>((resolve, reject) => {
      var calls : Object[] = [];
      var timeRange = new TimeRange(from, to,31);
      if(isSync) {
      this.downSync(timeRange, predicate, calls, resolve, reject);
      } else {
        this.downAsync(timeRange, predicate, calls, resolve, reject);
      }
    });
    return promise;
  }

  protected downSync(timeRange : TimeRange, predicate, calls : Object[], resolve, reject) : void {
    if(timeRange.isOk()) {
      this.downSyncParcial(0,timeRange, predicate, calls, {}, resolve, reject);
    } else {
      resolve(calls);
    }
  } 

  private downSyncParcial(index : number, timeRange : TimeRange, predicate, calls : Object[], errors, resolve, reject) : void {
    index++;
    this.PurecloudService.postAnalyticsUsersDetailsQuery(timeRange.fromParcial, timeRange.toParcial, predicate, index)
    .then((queryResponse) => this.processSyncParcial(queryResponse.userDetails, index, timeRange, predicate, calls, errors, resolve, reject))
    .catch((response) => this.downSyncParcialCatch(index, timeRange, predicate, calls, response, errors, resolve, reject));
  }
  private downSyncParcialCatch(index : number, timeRange : TimeRange, predicate, calls : Object[], response, errors, resolve, reject) : void {
    if(response.error !== undefined && response.error.errno !== undefined) {
      var count = errors[response.error.errno + "_" + index]
      if(count === undefined) {
        count = 0;
      }
      index--;

      if(count < 3) {
        count++;
        errors[response.error.errno + "_" + index] = count;
        console.log("Errors: ", errors);
        this.downSyncParcial(index, timeRange, predicate, calls, errors, resolve, reject);
      } else {
        reject(response);
      }
    } else {
      reject(response);
    }
  }
  private processSyncParcial(usersDetail : platformClient.Models.AnalyticsUserDetail[], index : number, timeRange : TimeRange, predicate, calls : Object[], errors, resolve, reject) {
    //console.log("index: ", index, ", length: ", usersDetail === undefined ? undefined : usersDetail.length);
    if(usersDetail == undefined || usersDetail.length == 0) {
      timeRange.addDate();
      this.downSync(timeRange, predicate, calls, resolve, reject);
    } else {
      timeRange.addCount(usersDetail.length);
      usersDetail.forEach((UserDetail) => this.processInternalCall(UserDetail, timeRange, calls));

      this.Toast.fire({
        title: 'Interacciones consideradas ' + calls.length + ' de ' + timeRange.count,
        timer: 1000
      });

      this.downSyncParcial(index, timeRange, predicate, calls, errors, resolve, reject);
    }
  }

  private downAsync(timeRange : TimeRange, predicate, calls : Object[], resolve, reject) {
    var info = {
      html: undefined,
      cancel: false
    }

    Swal.fire({
      title: 'Esperando...',
      html: 'Se espera la respuesta de Purecloud <b></b>',
      didOpen: () => this.didOpenSwal(timeRange, predicate, calls, info, resolve, reject),
      willClose: () => {
        info.cancel = true;
      }
    }).then((result) => {
      console.log(result);
    })    
  }
  private async didOpenSwal(timeRange : TimeRange, predicate, calls : Object[], info, resolve, reject) {
    Swal.showLoading();
    const content = Swal.getHtmlContainer();
    if (content) {
      const b = content.querySelector('b');
      if (b) {
        b.textContent = '';
        info.html = b;
      }

     /*  this.PurecloudService.postAnalyticsConversationsDetailsJobs(timeRange.from, timeRange.to, predicate)
      .then((queryResponse) => this.checkStatusJob(queryResponse.jobId, timeRange, predicate, calls, 0, info, resolve, reject))
      .catch((response) => reject(response)); */
    }
  } 
 
}

  