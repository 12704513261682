import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { ConversationDataPurecloudService } from './conversation-data-purecloud.service';
import { PurecloudService } from '../purecloud.service';
import { MapConversation }  from '../../../utils/map-conversation';
import { DateTime }  from '../../../utils/date-time';
import { TimeRange }  from '../../../services/purecloud/time-range';

@Injectable({
  providedIn: 'root'
})
export abstract class ConversationDataOpenedPurecloudService extends ConversationDataPurecloudService {

  constructor(private acloudService : PurecloudService) {
    super(acloudService);
  }
  protected processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange : TimeRange, calls : Object[]) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    if(timeRange.fromParcial.getTime() === timeRange.from.getTime() && from.getTime() <= timeRange.from.getTime() ) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
      return;
    }

    if(from.getTime() > timeRange.fromParcial.getTime()) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
    }
  }
}
